.App {
}
body {
}

Form.Group {
}
.formAll {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-image: url("../public/img/bg2.png");
}
h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.notificationAisle {
  /* border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: min( var(--sampler-comp-radius-scale),var(--sampler-comp-radius-scale-max) );
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
  color: rgb(255, 255, 255); */
  margin-left: 10rem;
  /* width: 15rem;
  height: 8rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeCounter {
  border-radius: 3px;
  background-color: rgba(58, 58, 58, 0.65);
  border-radius: 25px;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
  color: rgb(255, 255, 255);
  width: 15rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
.quoteContainer {
  border-radius: 3px;
  /* background: rgba(58, 58, 58, 0.65); */
  border-radius: 25px;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
  color: rgb(255, 255, 255);
  width: 28rem;
  max-height: 36rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 3rem;
  padding: 2.5rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.quoteContainer::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 500rem;
}

/* Handle */
.quoteContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 500rem;
}

/* Handle on hover */
.quoteContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.formSection {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.formBlock {
  width: 50%;
  padding: 3%;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.292);
  border-radius: 25px;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
  color: rgb(255, 255, 255);
  margin-bottom: 3rem;
}

.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: black;
}
.submitButton {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ListGroup {
  width: 25rem;
  margin: 5rem;
}

@media screen and (max-width: 1230px) {
  .formSection {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    flex-direction: column-reverse;
    align-items: center;
  }
  .notificationAisle {
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formBlock {
    width: auto;
    padding: 3%;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.292);
    border-radius: 25px;
    box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
    color: rgb(255, 255, 255);
    margin-bottom: 3rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
